<template>
  <div class="invitation-component">
    <div class="relative" v-if="inviteCode">
      <div
        class="login-section"
        style="
          min-height: calc(100vh - 142px);
          max-height: calc(100vh - 142px);
          overflow-y: auto;
        "
      >
        <v-card style="width: 450px" class="login-component pa-10 mx-auto my-4">
          <div class="d-flex flex-column align-center">
            <LoadingComponent v-if="pageLoading" />
            <template v-else>
              <div class="text-center title mb-2">
                Hello,
                <div
                  style="font-size: 14px; color: #4285f3"
                  class="mb-4 text-decoration-underline"
                >
                  {{ inviteData.email }}
                </div>

                You are invited to be a
                <span class="font-weight-bold">{{ inviteRole }}</span> of the
                <br />
                <span class="font-weight-bold"> {{ botName }} </span>
                on PinoyBot.
              </div>

              <v-avatar size="100" class="profile my-2" tile>
                <v-img :src="groupAvatar(inviteData)" class="group-image"
                  ><template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="black lighten-5"
                      ></v-progress-circular>
                    </v-row> </template
                ></v-img>
              </v-avatar>

              <v-btn
                @click="getAcknowledge()"
                color="primary"
                class="mt-6"
                large
                block
                :loading="isLoading"
                >Accept Invitation</v-btn
              >
            </template>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import LoadingComponent from "@/components/helpers/Loading";
import robotIcon from "@/assets/bots/robot-happy.svg";

export default {
  components: {
    LoadingComponent,
  },
  data() {
    return {
      pageLoading: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("invite", ["inviteData"]),

    inviteCode() {
      return _.get(this.$route, "query.code");
    },
    inviteRole() {
      return this.inviteData?.role_name;
    },
    botName() {
      return this.inviteData?.invitationable?.name;
    },
  },

  methods: {
    ...mapActions("bots", ["getBots"]),
    ...mapActions("invite", ["getInvite", "acceptInvite"]),
    ...mapMutations("invite", ["SET_INVITE_DATA"]),

    groupAvatar(item) {
      return _.get(item, "invitationable.icon")
        ? _.get(item, "invitationable.icon")
        : robotIcon;
    },

    getAcknowledge() {
      if (_.isEmpty(this.currentUser)) {
        console.log("redirect to login");
        this.$router.push({
          path: "/sign-in",
          query: { role: this.inviteRole, code: this.inviteCode },
        });
      } else {
        this.acceptInvite({
          code: this.inviteCode,
          data: { role: this.inviteRole, userId: this.currentUser.id },
        })
          .then(
            (res) => {
              this.goTo("/dashboard/playground", true);
              globalMessageHandler("Invitation Accepted.");
            },
            (err) => {
              globalErrorHandler(err);
            }
          )
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    goTo(route) {
      this.$router.push(route);
    },
  },

  mounted() {
    this.pageLoading = true;

    if (this.inviteCode) {
      this.getInvite(this.inviteCode)
        .then((res) => {
          console.log("res invited code:::", res);
          this.SET_INVITE_DATA(res.data);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    }
  },
};
</script>

<style lang="scss">
.invitation-component {
  .text-image {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px !important;
  }
}
</style>

<style lang="scss" scoped>
.invitation-component {
  .notfound {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    padding: 0px 30px;
  }
}
</style>
